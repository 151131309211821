/* Navbar container */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #B3DF62;
    padding: 20px 20px;
    color: white;
    font-family: 'Roboto Mono', monospace;
  }
  
  /* Logo styling */
  .navbar-logo {
    font-family: 'Roboto Mono', monospace;
    font-weight: normal;
    font-size: 32px;
    color: black;
    flex: 1;
    text-align: center;
  }
  
/* Button hover effect */
.navbar-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.logout-button {
    background-color: #4CAF50; /* Red background */
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 8px;
    font-family: 'Roboto Mono', monospace;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-left: 15px; /* Adds space from any adjacent elements */
}

.logout-button:hover {
    background-color: #45a049; /* Slightly darker red on hover */
    transform: scale(1.05); /* Slight zoom effect */
}

.logout-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(243, 67, 54, 0.4); /* Focus ring for accessibility */
}
@media (max-width: 768px) {
    .logout-button {
        width: 60%; /* Button takes full width on smaller screens */
        padding: 12px; /* Larger touch-friendly padding */
        margin: 10px 0; /* Space above and below the button */
        font-size: 18px; /* Larger font size for readability */
    }
}

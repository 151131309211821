* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto Mono;
}

/* Page container */
.login-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c2f33; /* Dark background color */
  color: #ffffff; /* Light text for contrast */
}

/* Form container */
.login-container {
  background-color: #23272a; /* Slightly lighter dark shade */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  width: 90%;
  max-width: 400px; /* Limits container width on larger screens */
  text-align: center;
}

/* Heading */
.login-container h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #ffffff;
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
}

label {
  text-align: left;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #a1a1a1; /* Light grey text for labels */
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

/* Buttons */
button {
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"] {
  background-color: #28c74a; /* Green for primary action */
  color: #ffffff;
  font-weight: bold;
}

button.toggle-button {
  background-color: #1e64d6; /* Blue for switching modes */
  color: #ffffff;
}

button:hover {
  opacity: 0.9;
}

/* Error message */
.error {
  color: #ff4d4f; /* Red for error messages */
  font-size: 0.9rem;
  margin-bottom: 10px;
}

/* Responsive Design */

/* Mobile view: Default styling (already optimized for mobile) */

/* Desktop view */
@media (min-width: 768px) {
  .login-container {
    padding: 40px;
  }

  .login-container h2 {
    font-size: 2rem;
  }

  input,
  button {
    font-size: 1.1rem;
    padding: 12px;
  }

  button {
    margin: 15px 0;
  }
}
.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
::-webkit-scrollbar {
  display: none;
}

/* General Table Styling */
table {
  width: 100%;
  border-collapse: collapse; /* Remove gaps between cells */
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  background-color: #ffffff; /* White background for table */
  border-radius: 8px; /* Add rounded corners */
  overflow: hidden; /* Ensure content does not overflow rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
}

/* Table Header Styling */
th {
  background-color: #4CAF50; /* Green header background */
  color: white; /* White text for header */
  font-weight: bold; /* Make header text bold */
  text-align: center; /* Center header text */
  padding: 12px 8px; /* Adjust padding for a cleaner look */
  font-size: 18px; /* Increase header font size slightly */
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Table Cell Styling */
td {
  text-align: center; /* Center-align data */
  padding: 10px; /* Add more padding inside cells */
  font-size: 16px; /* Maintain readable font size */
  color: #333; /* Use a darker gray for text */
  vertical-align: middle; /* Align content vertically in the center */
}

/* Row Styling */
tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}

tr:hover {
  background-color: #e8f5e9; /* Slightly lighter green highlight on hover */
}

/* Adjust Empty Space Around Table */
.table-container {
  background-color: #282c34; /* Match the body background */
  padding: 20px;
  border-radius: 8px;
  max-height: 500px;
  max-width: 1200px; /* Restrict maximum width for better alignment */
  margin: 0 auto; /* Center the table horizontally */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Add shadow for container */
  overflow-y: auto;
}

/* Responsive Design for Tables */
@media screen and (max-width: 768px) {
  table {
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  th, td {
    padding: 8px; /* Adjust padding for smaller screens */
  }

  .table-container {
    padding: 10px; /* Reduce padding around the table */
  }
}

@media screen and (max-width: 480px) {
  table {
    font-size: 12px; /* Further reduce font size */
  }

  th, td {
    padding: 6px; /* Compact padding */
  }
}

h1 {
  font-size: 28px; /* Larger font size for prominence */
  color: #4CAF50; /* Green color to match the table header */
  font-family: 'Roboto Mono', monospace; /* Consistent font with the design */
  text-align: center; /* Center the title */
  margin: 20px 0; /* Space above and below the title */
}

/* Adjust the table title within the table container */
.table-container h1 {
  margin-bottom: 20px; /* Add spacing between the title and table */
}


body {
  background-color: #282c34;
}

html, body {
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  height: 100%; /* Ensure the page covers the full viewport */
  overscroll-behavior: none;
}


.create-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #4CAF50;
  font-size: 14px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.toast.show {
  opacity: 1;
  transform: translateY(0);
}

.form-buttons button {
  width: 48%; /* Make buttons fit nicely side by side */
  margin: 5px 1%; /* Add some spacing */
  padding: 10px;
  font-size: 14px;
  background-color: #4CAF50; /* Green color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-buttons button:hover {
  background-color: #45a049; /* Darker green on hover */
}

@media screen and (max-width: 480px) {
  .modal-content {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  label {
    font-size: 12px; /* Reduce label font size */
  }

  input, textarea {
    font-size: 12px; /* Adjust input font size */
    padding: 8px; /* Compact padding */
  }

  .form-buttons button {
    font-size: 12px; /* Smaller button font */
    padding: 8px; /* Compact button padding */
  }
}
/* Container to hold the search bar and button side by side */
.search-container {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between search bar and button */
  justify-content: space-between; /* Distribute items evenly */
}

/* For mobile screens, stack the search bar and button vertically */
@media screen and (max-width: 768px) {
  .search-container {
    flex-direction: column; /* Stack elements vertically */
    gap: 10px; /* Reduce spacing on smaller screens */
    width: 100%; /* Ensure the container fits the screen */
  }
}

/* Search bar styling */
.search-bar {
  display: flex;
  align-items: center;
  background-color: #d3d3d3; /* Light gray background */
  border-radius: 20px; /* Rounded corners */
  padding: 10px 15px; /* Ensure consistent padding */
  flex-grow: 1; /* Allow the search bar to expand and fill available space */
  height: 50px; /* Set consistent height */
  box-sizing: border-box; /* Include padding and border in height */
}

.search-bar label {
  font-family: 'Roboto Mono', monospace;
  color: rgb(65, 64, 64);
  margin-right: 10px;
  font-size: 18px; /* Match font size with input */
}

.search-bar input {
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Roboto Mono', monospace;
  font-size: 18px; /* Consistent font size */
  color: black;
  flex-grow: 1; /* Allow the input to stretch and fill the bar */
  min-width: 0; /* Prevent overflow issues */
}

/* Button styling */
.create-button {
  background-color: #4CAF50; /* Green button background */
  color: black;
  padding: 10px 20px; /* Ensure padding matches search bar */
  border: none;
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  font-family: 'Roboto Mono', monospace;
  font-size: 18px; /* Match font size with search bar label */
  height: 50px; /* Match height with search bar */
  display: flex; /* Center text vertically */
  align-items: center;
  justify-content: center;
  flex-shrink: 0; /* Prevent the button from shrinking */
  box-sizing: border-box; /* Include padding and border in height */
  transition: background-color 0.3s;
}

.create-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* For very small screens, adjust button size and font further */
@media screen and (max-width: 480px) {
  .create-button {
    font-size: 16px; /* Slightly smaller font for small screens */
    padding: 8px 15px; /* Compact padding */
    height: auto; /* Allow height to adjust naturally */
  }
}
